import React from "react";
import SideNavbar from "../../SideNavbar";
import "../../links.css";
import logo from "../images/logo.png";

function menu() {
  return (
    <>
      <nav class="uk-navbar-container hide-icon">
        <div class="uk-container">
          <div uk-navbar="true">
            <div class="uk-navbar-left uk-width-1-2">
              <img src={logo} alt="" />
            </div>

            <div class="uk-navbar-right">
              <div uk-toggle="target: #offcanvas-usage ">
                <a
                  class="uk-navbar-toggle uk-navbar-toggle-animate hide-icon"
                  uk-navbar-toggle-icon="true"
                  href="/"></a>
              </div>

              <div id="offcanvas-usage" uk-offcanvas="true">
                <div
                  class="uk-offcanvas-bar "
                  style={{ background: "#5FBFBF" }}
                >
                  <button
                    class="uk-offcanvas-close"
                    type="button"
                    uk-close="true"
                  ></button>
                  <div className="uk-margin-top">

                  <SideNavbar />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

// {showSidebar && (
//     <div className="uk-width-auto@s uk-background-primary uk-padding-small uk-card-body" style={{ minHeight: '100vh', maxWidth: '250px', overflowY: 'auto' }}>
//       <SideNavbar />
//     </div>
//   )}

export default menu;

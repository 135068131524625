import React from 'react'
import Maps from './Dashboard components/Maps'
import Coc from './Dashboard components/Coc'

function Map() {
  return <>
  <div className="uk-grid uk-grid-small">
        {/* left side */}
        <div className="uk-width-expand@m ">
          {/* calling map */}
          <Maps />
          {/* Contact Overview and Contribution */}
          <Coc />
        </div>
        
      </div>
  </>
}

export default Map
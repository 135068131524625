// import React from "react";
// import './links.css'
// import Map from "./Dashboard components/Maps"
// import Coc from "./Dashboard components/Coc";
// import Tasktofulfill from "./Dashboard components/Tasktofulfill";
// import Upcomingevents from "./Dashboard components/Upcomingevents";
// function Dashboard() {
//   // uk-background-primary
//   return (
//     <>
//       <div className="uk-flex uk-flex-between">
//         {/* left side */}
//         <div className="uk-width-expand@m  uk-padding-small">
//           {/*calling map */}
//           <Map/>
//           {/* Contact Overview and Contribution */}
//           <Coc/>
//         </div>
//         <div className="uk-width-1-4@m ">
//           {/* Task to Fulfill */}
//           <Tasktofulfill/>
//           {/* upcoming event */}
//           <Upcomingevents/>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Dashboard;

import React from "react";
import './links.css'
import Maps from "./Dashboard components/Maps"
import Coc from "./Dashboard components/Coc";
import Tasktofulfill from "./Dashboard components/Tasktofulfill";
import Upcomingevents from "./Dashboard components/Upcomingevents";

function Dashboard() {
  return (
    <>
    {/*  uk-background-primary */}
      <div className="uk-grid uk-grid-small">
        {/* left side */}
        <div className="uk-width-expand@m ">
          {/* calling map */}
          <Maps />
          {/* Contact Overview and Contribution */}
          <Coc />
        </div>
        <div className="uk-width-1-4@m ">
          
          {/* Task to Fulfill */}
          <Tasktofulfill />
          {/* upcoming event */}
          <Upcomingevents />
        </div>
      </div>
    </>
  );
}

export default Dashboard;

import React from 'react';
import ComparisonChart from "../assets/other/Charts";
import Contribution from '../assets/other/Contribution';


function Coc() {
      // for chart
  const expensesData = [10500, 8000, 13000, 6000, 4000, 11000];
  const incomeData = [13000, 10500, 16500, 8000, 7000, 14000];
  return <>
  <div>
  <div class="uk-child-width-1-2@s uk-margin-top uk-grid-match " uk-grid="true">
            <div>
              <div style={{ background: "#fcfcfc", borderRadius: "6px" }}>
                <div
                  style={{
                    background: "#f4f4f5",
                    borderTopLeftRadius: "6px",
                    borderTopRightRadius: "6px",
                    padding: "1.5vh",
                  }}
                >
                  <b style={{ color: "black", margin: "1vw" }}>
                    contacts Overview
                  </b>
                </div>
                <div>
                  <ComparisonChart
                    expensesData={expensesData}
                    incomeData={incomeData}
                  />
                </div>
              </div>
            </div>
            <div>
              <Contribution/>
            </div>
          </div>
          </div>
  {/* <div>
  <div class="uk-child-width-1-2@s uk-margin-top " uk-grid="true">
            <div>
              <div style={{ background: "#fcfcfc", borderRadius: "6px" }}>
                <div
                  style={{
                    background: "#f4f4f5",
                    borderTopLeftRadius: "6px",
                    borderTopRightRadius: "6px",
                    padding: "1.5vh",
                  }}
                >
                  <b style={{ color: "black", margin: "1vw" }}>
                    contacts Overview
                  </b>
                </div>
                <div>
                  <ComparisonChart
                    expensesData={expensesData}
                    incomeData={incomeData}
                  />
                </div>
              </div>
            </div>

            <div>
              <div style={{ background: "#fcfcfc", borderRadius: "6px" }}>
                <div
                  style={{
                    background: "#f4f4f5",
                    borderTopLeftRadius: "6px",
                    borderTopRightRadius: "6px",
                    padding: "1.5vh",
                  }}
                >
                  <b style={{ color: "black", margin: "1vw" }}>Contributions</b>
                </div>
                <div className="uk-child-width-1-3@s uk-grid">
                  <div>
                    <div className="uk-card uk-padding-small uk-margin-small-top">
                      <div className="uk-margin">
                        <h4 className=" uk-margin-remove">
                          <b>$87,495</b>
                        </h4>
                        <p
                          className=" uk-margin-remove"
                          style={{
                            fontSize: "1.5vh",
                            width: "15vw",
                            color: "#5FBFBF",
                          }}
                        >
                          Total Amount Raised
                        </p>
                      </div>
                      <div className="uk-margin">
                        <h4 className=" uk-margin-remove">
                          <b>7,127</b>
                        </h4>
                        <p
                          className=" uk-margin-remove"
                          style={{
                            fontSize: "1.5vh",
                            width: "15vw",
                            color: "#5FBFBF",
                          }}
                        >
                          Number of Contributions
                        </p>
                      </div>
                      <div className="uk-margin">
                        <h4 className=" uk-margin-remove">
                          <b>$50</b>
                        </h4>
                        <p
                          className=" uk-margin-remove"
                          style={{
                            fontSize: "1.5vh",
                            width: "15vw",
                            color: "#5FBFBF",
                          }}
                        >
                          Most Common Contribution
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="uk-card">
                      <div className=" uk-card-body uk-padding-remove">
                        <div style={{ marginLeft: "1vh", marginTop: "-1.5vh" }}>
                          <CircularProgressBar />
                        </div>
                      </div>
                      <div
                        style={{
                          width: "80vw",
                          fontSize: "1.5vh",
                          display: "flex",
                          marginLeft: "3vw",
                        }}
                      >
                        <div
                          style={{
                            height: "1vh",
                            width: "2vh",
                            marginTop: "0.6vh",
                            borderRadius: "20px",
                            marginRight: "1vh",
                            background: "#59bec0",
                          }}
                        ></div>
                        <div>This Month</div>
                        <div
                          style={{
                            height: "1vh",
                            width: "2vh",
                            borderRadius: "20px",
                            margin: " 0.6vh 1vh",
                            background: "#adddde",
                          }}
                        ></div>
                        <div>Last Month</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div> */}
  </>
}

export default Coc
import React from 'react'

function Maps() {
  return <>
  <div
            className="uk-background-muted"
            style={{
              width: "calc(100%)",
              height: "327px",
              borderRadius: "10px",
            }}
          >
            <iframe
              title="Google Map"
              width="100%"
              height="100%"
              style={{ border: "0", borderRadius: "10px" }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.1062088336766!2d-86.15806648580395!3d39.76812407943242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886b50de04ddcb3f%3A0x3445e7f1b7cb9c8d!2sIndianapolis%2C%20IN%2C%20USA!5e0!3m2!1sen!2sin!4v1621389118741!5m2!1sen!2sin"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </div>
  </>
}

export default Maps
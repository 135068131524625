// write by me
// import React from "react";
// import { Outlet, Link } from "react-router-dom";
// import logo from "./assets/images/logo.png";
// import profile from "./assets/images/profilepic.png";
// import SideNavbar from "./SideNavbar";

// function TopNavbar() {
//   return (
//     <>
//       <nav>
//         <div class="uk-container ">
//           <div uk-navbar="uk-navbar">
//             <div class="uk-navbar-left">
//               <Link to="/dashboard">
//                 <img
//                   class="uk-navbar-item uk-logo uk-height-1-2"
//                   width={250}
//                   src={logo}
//                   alt=""
//                 />
//               </Link>
//             </div>

//             <div class="uk-navbar-right">
//               <h3 className="uk-text-bold">
//                 Jane Vanice{" "}
//                 <span
//                   uk-icon="icon:  chevron-down; ratio: 2"
//                   style={{ color: "#5FBFBF" }}
//                 ></span>
//               </h3>

//               <Link to="/dashboard">
//                 <img
//                   class="uk-navbar-item uk-logo uk-height-1-2"
//                   width={80}
//                   src={profile}
//                   alt=""
//                 />
//               </Link>
//             </div>
//           </div>
//         </div>
//       </nav>

//       <div class="uk-child-width-1-5@s uk-grid">
//         <div style={{ background: "#5FBFBF", width:'25vw' }} uk-height-viewport="expand: true">
//           <div class="uk-padding-small uk-card-body">
//             <SideNavbar />
//           </div>
//         </div>
//         <div className="uk-width-expand@m">
//           <div class="uk-child-width-1-2 uk-card-body uk-padding-remove ">
//             <Outlet />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default TopNavbar;


import React from "react";
import { Outlet, Link } from "react-router-dom";
import logo from "./assets/images/logo.png";
import SideNavbar from "./SideNavbar";
import MenuIcon from "./assets/other/menu";
import './links.css'

function TopNavbar() {
  return (
    <>
      <nav >
          <div uk-navbar="uk-navbar">
            <div>
              <div className="uk-navbar-left hide-icon">
                <MenuIcon/>
              </div>
              <Link className="sidebar-container" to="/dashboard">
                <img
                  class=" uk-width-1-4"
                  width={250}
                  src={logo}
                  alt=""
                />
              </Link>
            </div>
        </div>
      </nav>

      <div className="uk-grid-collapse" uk-grid="true">
          
        <div
          className='uk-width-auto@s sidebar-container uk-padding-small uk-card-body'
          style={{ minHeight: "100vh", maxWidth: "250px", background:'#5FBFBF' }}
        >
          <SideNavbar />
        </div>

        
          <div className="uk-width-expand@m outlet-container">
            <div className="uk-margin-left uk-margin-right">
              <Outlet />
            </div>
          </div>
      </div>
    </>
  );
}

export default TopNavbar;

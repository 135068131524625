import React from "react";
import Pichart from "./assets/other/Pichart";
import "./links.css";

function Settings() {
  return (
    <>
      <div style={{ background: "#fcfcfc", borderRadius: "6px" }}>
        <div
          style={{
            background: "#f4f4f5",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            padding: "1.5vh",
          }}
        >
          <b style={{ color: "black", margin: "1vw" }}>Contributions</b>
        </div>
        <div
          class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s"
          uk-grid="true"
        >
          <div class="uk-card-media-left uk-cover-container uk-padding">
            <div className="uk-margin">
              <h4 className=" uk-margin-remove">
                <b>$87,495</b>
              </h4>
              <p
                className=" uk-margin-remove"
                style={{
                  fontSize: "1.5vh",
                  width: "15vw",
                  color: "#5FBFBF",
                }}
              >
                Total Amount Raised
              </p>
            </div>
            <div className="uk-margin">
              <h4 className=" uk-margin-remove">
                <b>7,127</b>
              </h4>
              <p
                className=" uk-margin-remove"
                style={{
                  fontSize: "1.5vh",
                  width: "15vw",
                  color: "#5FBFBF",
                }}
              >
                Number of Contributions
              </p>
            </div>
            <div className="uk-margin">
              <h4 className=" uk-margin-remove">
                <b>$50</b>
              </h4>
              <p
                className=" uk-margin-remove"
                style={{
                  fontSize: "1.5vh",
                  width: "15vw",
                  color: "#5FBFBF",
                }}
              >
                Most Common Contribution
              </p>
            </div>
          </div>
          <div>
            <div class="uk-card-body uk-padding-remove">
              <div>
                <Pichart />
              </div>
              <div>
                <div  uk-grid='true'>
                  <div className="uk-flex">
                    <p className="uk-badge-custom"></p>
                    <div className="uk-margin-right">This Month</div>
                  </div>
                  <div className="uk-flex uk-padding-remove">
                    <p className="uk-badge-custom"></p>
                    <div>Last Month</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;

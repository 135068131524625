import React from 'react'

function Upcomingevents() {
  return <>
  {/* <div className="uk-margin-bottom  uk-margin-small-top">
            <div
              style={{
                marginTop: "2vh",
                paddingTop: "1.5vh",
                background: "#f4f4f5",
                borderRadius: "6px",
                paddingBottom:'15px'
              }}
            >
              <div className="uk-padding-top" style={{marginBottom:'1.1vh'}}>
                <b style={{ color: "black", marginLeft: "1.5vw"}}>
                  Upcoming Events
                </b>
              </div>
              <div
                className="uk-flex uk-flex-middle uk-grid-collapse uk-child-width-expand@s"
                uk-grid="true"
                style={{
                  background: "white",
                  width: "95%",
                  height: "7vh",
                  borderRadius: "1vh",
                  marginTop: "1.2vh",
                  paddingTop: "0.5vh",
                  margin: "auto",
                }}
              >
                <div className="uk-flex uk-flex-center uk-margin uk-margin-small-left uk-width-1-6 uk-padding-remove uk-flex-left@s">
                  <span
                    uk-icon="icon: calendar; ratio: 1;"
                    style={{ color: "#5FBFBF" }}
                  ></span>
                </div>

                <div className="uk-flex uk-flex-expand uk-flex-column">
                  <div>
                    <b style={{ color: "black" ,fontSize: "1.6vh"}}>
                      New York Charity Ball
                    </b>
                  </div>
                  <div>
                    <p style={{ fontSize: "1.2vh", color: "gray" }}>
                      Feb 02, 2023
                    </p>
                  </div>
                </div>

                <div className="uk-flex uk-flex-center uk-margin-small-right uk-margin-bottom uk-width-1-4 uk-flex-right@s">
                  <button
                    style={{
                      width: "4.5vw",
                      height: "3.5vh",
                      borderRadius: "15px",
                      fontSize:'1.8vh',
                      border:'1px solid grey',
                      background:'white'
                    }}
                  >
                    Details
                  </button>
                </div>
              </div>
              <div
                className="uk-flex uk-flex-middle uk-margin-small-top uk-grid-collapse uk-child-width-expand@s"
                uk-grid="true"
                style={{
                  background: "white",
                  width: "95%",
                  height: "7vh",
                  borderRadius: "1vh",
                  marginTop: "1.2vh",
                  paddingTop: "0.5vh",
                  margin: "auto",
                }}
              >
                <div className="uk-flex uk-flex-center uk-margin uk-margin-small-left uk-width-1-6 uk-padding-remove uk-flex-left@s">
                  <span
                    uk-icon="icon: calendar; ratio: 1;"
                    style={{ color: "#5FBFBF" }}
                  ></span>
                </div>

                <div className="uk-flex uk-flex-expand uk-flex-column">
                  <div>
                    <b style={{ color: "black" ,fontSize: "1.8vh"}}>
                      Rally for vote
                    </b>
                  </div>
                  <div>
                    <p style={{ fontSize: "1.2vh", color: "gray" }}>
                      Feb 18, 2023
                    </p>
                  </div>
                </div>

                <div className="uk-flex uk-flex-center uk-margin-small-right uk-margin-bottom uk-width-1-4 uk-flex-right@s">
                  <button
                    style={{
                      width: "4.5vw",
                      height: "3.5vh",
                      borderRadius: "15px",
                      fontSize:'1.8vh',
                      border:'1px solid grey',
                      background:'white'
                    }}
                  >
                    Details
                  </button>
                </div>
              </div>
              <div
                className="uk-flex uk-flex-middle uk-margin-small-top uk-grid-collapse uk-child-width-expand@s"
                uk-grid="true"
                style={{
                  background: "white",
                  width: "95%",
                  height: "7vh",
                  borderRadius: "1vh",
                  marginTop: "1.2vh",
                  paddingTop: "0.5vh",
                  margin: "auto",
                }}
              >
                <div className="uk-flex uk-flex-center uk-margin uk-margin-small-left uk-width-1-6 uk-padding-remove uk-flex-left@s">
                  <span
                    uk-icon="icon: calendar; ratio: 1;"
                    style={{ color: "#5FBFBF" }}
                  ></span>
                </div>

                <div className="uk-flex uk-flex-expand uk-flex-column">
                  <div>
                    <b style={{ color: "black" ,fontSize: "1.8vh"}}>
                      File the nomination
                    </b>
                  </div>
                  <div>
                    <p style={{ fontSize: "1.2vh", color: "gray" }}>
                      Feb 23, 2023
                    </p>
                  </div>
                </div>

                <div className="uk-flex uk-flex-center uk-margin-small-right uk-margin-bottom uk-width-1-4 uk-flex-right@s">
                  <button
                    style={{
                      width: "4.5vw",
                      height: "3.5vh",
                      borderRadius: "15px",
                      fontSize:'1.8vh',
                      border:'1px solid grey',
                      background:'white'
                    }}
                  >
                    Details
                  </button>
                </div>
              </div>
              <div
                className="uk-flex uk-flex-middle uk-margin-small-top uk-grid-collapse uk-child-width-expand@s"
                uk-grid="true"
                style={{
                  background: "white",
                  width: "95%",
                  height: "7vh",
                  borderRadius: "1vh",
                  marginTop: "1.2vh",
                  paddingTop: "0.5vh",
                  margin: "auto",
                }}
              >
                <div className="uk-flex uk-flex-center uk-margin uk-margin-small-left uk-width-1-6 uk-padding-remove uk-flex-left@s">
                  <span
                    uk-icon="icon: calendar; ratio: 1;"
                    style={{ color: "#5FBFBF" }}
                  ></span>
                </div>

                <div className="uk-flex uk-flex-expand uk-flex-column">
                  <div>
                    <b style={{ color: "black" ,fontSize: "1.8vh"}}>
                      President Gala
                    </b>
                  </div>
                  <div>
                    <p style={{ fontSize: "1.2vh", color: "gray" }}>
                      Feb 28, 2023
                    </p>
                  </div>
                </div>

                <div className="uk-flex uk-flex-center uk-margin-small-right uk-margin-bottom uk-width-1-4 uk-flex-right@s">
                  <button
                    style={{
                      width: "4.5vw",
                      height: "3.5vh",
                      borderRadius: "15px",
                      fontSize:'1.8vh',
                      border:'1px solid grey',
                      background:'white'
                    }}
                  >
                    Details
                  </button>
                </div>
              </div>
            </div>
          </div> */}


          {/* Boom */}
          <div className="uk-margin-bottom">
        <div
          style={{
            paddingTop: "1.5vh",
            background: "#f4f4f5",
            borderRadius: "6px",
            paddingBottom:'15px'
          }}
        >
          <div className="uk-padding-top" style={{ marginBottom: "1.1vh" }}>
            <b
              style={{
                color: "black",
                marginLeft: "1.5vw"
              }}
            >
              Upcoming Events
            </b>
          </div>
          <div
            className="uk-flex uk-flex-middle uk-margin-small " /* list div*/
            uk-grid="true"
            style={{
              background: "white",
              width: "95%",
              padding:'3% 0%',
              borderRadius: "1vh",
              margin: "auto",
            }}
          >
            <div
              className="uk-width-1-6 uk-padding-remove uk-flex-left@s"
            >
              <span className='uk-margin-small-left'
                    uk-icon="icon: calendar; ratio: 1;"
                    style={{ color: "#5FBFBF" }}
                  ></span>
            </div>

            <div className="uk-flex uk-flex-column uk-padding-remove uk-width-expand uk-margin-remove" >
              <div>
                <b className="uk-padding-remove" style={{ color: "black", fontSize: "1.7vh" }}>
                New York Charity Ball
                </b>
                <p className="uk-padding-remove uk-margin-remove" style={{ fontSize: "1.2vh", color: "gray" }}>Feb 02, 2023</p>
              </div>
              <div>
              </div>
            </div>

            <div  className='uk-width-1-3 uk-padding-remove'>
              <button className=""
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: "15px",
                  fontSize: "1.8vh",
                  border: "1px solid grey",
                  background: "white",
                  padding:'2%',
                  marginLeft:'30%'
                }}
              >
                Details
              </button>
            </div>
          </div>
          <div
            className="uk-flex uk-flex-middle uk-margin-small"
            uk-grid="true"
            style={{
              background: "white",
              width: "95%",
              padding:'3% 0%',
              borderRadius: "1vh",
              margin: "auto",
            }}
          >
            <div
              className="uk-width-1-6 uk-padding-remove uk-flex-left@s"
            >
              <span className='uk-margin-small-left'
                    uk-icon="icon: calendar; ratio: 1;"
                    style={{ color: "#5FBFBF" }}
                  ></span>
            </div>

            <div className="uk-flex uk-flex-column uk-padding-remove uk-width-expand">
              <div>
                <b className="uk-padding-remove" style={{  color: "black", fontSize: "1.7vh" }}>
                  Rally for vote
                </b>
                <p className="uk-padding-remove uk-margin-remove" style={{ fontSize: "1.2vh", color: "gray" }}>Feb 18, 2023</p>
              </div>
              <div>
              </div>
            </div>

            <div className="uk-width-1-3 uk-padding-remove">
              <button
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: "15px",
                  fontSize: "1.8vh",
                  border: "1px solid grey",
                  background: "white",
                  padding:'2%',
                  marginLeft:'30%'
                }}
              >
                Details
              </button>
            </div>
          </div>
          <div
            className="uk-flex uk-flex-middle uk-margin-small"
            uk-grid="true"
            style={{
              background: "white",
              width: "95%",
              padding:'3% 0%',
              borderRadius: "1vh",
              margin: "auto",
            }}
          >
            <div
              className="uk-width-1-6 uk-padding-remove uk-flex-left@s"
            >
             <span className='uk-margin-small-left'
                    uk-icon="icon: calendar; ratio: 1;"
                    style={{ color: "#5FBFBF" }}
                  ></span>
            </div>

            <div className="uk-flex uk-flex-column uk-padding-remove uk-width-expand">
              <div>
                <b className="uk-padding-remove" style={{  color: "black", fontSize: "1.7vh" }}>
                  Submit proposal
                </b>
                <p className="uk-padding-remove uk-margin-remove" style={{ fontSize: "1.2vh", color: "gray" }}>Feb 23, 2023</p>
              </div>
              <div>
              </div>
            </div>

            <div className="uk-width-1-3 uk-padding-remove">
              <button
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: "15px",
                  fontSize: "1.8vh",
                  border: "1px solid grey",
                  background: "white",
                  padding:'2%',
                  marginLeft:'30%'
                }}
              >
                Details
              </button>
            </div>
          </div>
          <div
            className="uk-flex uk-flex-middle uk-margin-small"
            uk-grid="true"
            style={{
              background: "white",
              width: "95%",
              padding:'3% 0%',
              borderRadius: "1vh",
              margin: "auto",
            }}
          >
            <div
              className="uk-width-1-6 uk-padding-remove uk-flex-left@s"
            >
              <span className='uk-margin-small-left'
                    uk-icon="icon: calendar; ratio: 1;"
                    style={{ color: "#5FBFBF" }}
                  ></span>
            </div>

            <div className="uk-flex uk-flex-column uk-padding-remove uk-width-expand">
              <div>
                <b className="uk-padding-remove" style={{  color: "black", fontSize: "1.7vh" }}>
                  Meet with vendors
                </b>
                <p className="uk-padding-remove uk-margin-remove" style={{ fontSize: "1.2vh", color: "gray" }}>Feb 28, 2023</p>
              </div>
              
            </div>

            <div className="uk-width-1-3 uk-padding-remove">
              <button
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: "15px",
                  fontSize: "1.8vh",
                  border: "1px solid grey",
                  background: "white",
                  padding:'2%',
                  marginLeft:'30%'
                }}
              >
                Details
              </button>
            </div>
          </div>
          
        </div>
      </div>
  </>
}

export default Upcomingevents
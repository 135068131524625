import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import TopNavbar from '../pages/TopNavbar';
import Calendar from '../pages/Calendar';
import Contact from '../pages/Contact';
import Dashboard from '../pages/Dashboard';
import Finance from '../pages/Finance';
import Reports from '../pages/Reports';
import Settings from '../pages/Settings';
import Setup from '../pages/Setup';
import Tasks from '../pages/Tasks';
import Map from '../pages/Map';

const Root = () => {
    return <>
      <Router >
          <Routes>
            <Route  path="*" element={<Navigate to={"/"}/>} /> 
            <Route path="/" element={<TopNavbar />} >
              <Route index element={<Dashboard />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/contact" element={<Contact />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path="/finance" element={<Finance />} />
              <Route path="/map" element={<Map />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/setup" element={<Setup />} />
              <Route path="/tasks" element={<Tasks />} />
            </Route >
          </Routes>
      </Router>
    </>
  }
  
  export default Root
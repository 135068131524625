import React, { useState } from "react";

function Tasktofulfill() {
  // for check box
  const [isChecked, setIsChecked] = useState(false);
  const [filePaper, setFilePaper] = useState(false);
  const [First, setFirst] = useState(false);
  const [Second, setSecond] = useState(false);
  return (
    <>
      <div className="uk-margin-bottom">
        <div
          style={{
            paddingTop: "1.5vh",
            background: "#f4f4f5",
            borderRadius: "6px",
            paddingBottom:'15px'
          }}
        >
          <div className="uk-padding-top" style={{ marginBottom: "1.1vh" }}>
            {" "}
            <b
              style={{
                color: "black",
                marginLeft: "1.5vw"
              }}
            >
              Task to Fulfill
            </b>
          </div>
          <div
            className="uk-flex uk-flex-middle uk-margin-small " /* list div*/
            uk-grid="true"
            style={{
              background: "white",
              width: "95%",
              padding:'3% 0%',
              borderRadius: "1vh",
              margin: "auto",
            }}
          >
            <div
              className="uk-width-1-6 uk-padding-remove uk-flex-left@s"
            >
              <input
                className={`uk-checkbox uk-margin-small-left black-border ${
                  isChecked ? "uk-checkbox-grey" : ""
                }`}
                type="checkbox"
                checked={isChecked}
                onChange={()=>setIsChecked(!isChecked)}
              />
            </div>

            <div className="uk-flex uk-flex-column uk-padding-remove uk-width-expand">
              <div>
                <b className="uk-padding-remove" style={{ color: "black", fontSize: "1.7vh" }}>
                Collect Donations
                </b>
                <p className="uk-padding-remove uk-margin-remove" style={{ fontSize: "1.2vh", color: "gray" }}>Feb 02, 2023</p>
              </div>
              <div>
              </div>
            </div>

            <div className="uk-width-auto uk-margin-right">
              <button
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: "15px",
                  fontSize: "1.8vh",
                  border: "1px solid grey",
                  background: "white",
                  padding:'8%'
                }}
              >
                Details
              </button>
            </div>
          </div>
          <div
            className="uk-flex uk-flex-middle uk-margin-small"
            uk-grid="true"
            style={{
              background: "white",
              width: "95%",
              padding:'3% 0%',
              borderRadius: "1vh",
              margin: "auto",
            }}
          >
            <div
              className="uk-width-1-6 uk-padding-remove uk-flex-left@s"
            >
              <input
                className={`uk-checkbox uk-margin-small-left black-border ${
                  filePaper ? "uk-checkbox-grey" : ""
                }`}
                type="checkbox"
                checked={filePaper}
                onChange={()=>setFilePaper(!filePaper)}
              />
            </div>

            <div className="uk-flex uk-flex-column uk-padding-remove uk-width-expand">
              <div>
                <b className="uk-padding-remove" style={{ color: "black", fontSize: "1.7vh" }}>
                  File paper work
                </b>
                <p className="uk-padding-remove uk-margin-remove" style={{ fontSize: "1.2vh", color: "gray" }}>Feb 18, 2023</p>
              </div>
              <div>
              </div>
            </div>

            <div className="uk-width-auto uk-margin-right">
              <button
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: "15px",
                  fontSize: "1.8vh",
                  border: "1px solid grey",
                  background: "white",
                  padding:'8%'
                }}
              >
                Details
              </button>
            </div>
          </div>
          <div
            className="uk-flex uk-flex-middle uk-margin-small"
            uk-grid="true"
            style={{
              background: "white",
              width: "95%",
              padding:'3% 0%',
              borderRadius: "1vh",
              margin: "auto",
            }}
          >
            <div
              className="uk-width-1-6 uk-padding-remove uk-flex-left@s"
            >
              <input
                className={`uk-checkbox uk-margin-small-left black-border ${
                  First ? "uk-checkbox-grey" : ""
                }`}
                type="checkbox"
                checked={First}
                onChange={()=>setFirst(!First)}
              />
            </div>

            <div className="uk-flex uk-flex-column uk-padding-remove uk-width-expand">
              <div>
                <b className="uk-padding-remove" style={{  color: "black", fontSize: "1.7vh" }}>
                  Submit proposal
                </b>
                <p className="uk-padding-remove uk-margin-remove" style={{ fontSize: "1.2vh", color: "gray" }}>Feb 23, 2023</p>
              </div>
              <div>
              </div>
            </div>

            <div className="uk-width-auto uk-margin-right">
              <button
                style={{
                 width: "auto",
                  height: "auto",
                  borderRadius: "15px",
                  fontSize: "1.8vh",
                  border: "1px solid grey",
                  background: "white",
                  padding:'8%'
                }}
              >
                Details
              </button>
            </div>
          </div>
          <div
            className="uk-flex uk-flex-middle uk-margin-small"
            uk-grid="true"
            style={{
              background: "white",
              width: "95%",
              padding:'3% 0%',
              borderRadius: "1vh",
              margin: "auto",
            }}
          >
            <div
              className="uk-width-1-6 uk-padding-remove uk-flex-left@s"
            >
              <input
                className={`uk-checkbox uk-margin-small-left black-border ${
                  Second ? "uk-checkbox-grey" : ""
                }`}
                type="checkbox"
                checked={Second}
                onChange={()=>setSecond(!Second)}
              />
            </div>

            <div className="uk-flex uk-flex-column uk-padding-remove uk-width-expand">
              <div>
                <b className="uk-padding-remove" style={{  color: "black", fontSize: "1.7vh" }}>
                  Meet with vendors
                </b>
                <p className="uk-padding-remove uk-margin-remove" style={{ fontSize: "1.2vh", color: "gray" }}>Feb 28, 2023</p>
              </div>
              
            </div>

            <div className="uk-width-auto uk-margin-right">
              <button
                style={{
                 width: "auto",
                  height: "auto",
                  borderRadius: "15px",
                  fontSize: "1.8vh",
                  border: "1px solid grey",
                  background: "white",
                  padding:'8%'
                }}
              >
                Details
              </button>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
}

export default Tasktofulfill;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active {
    background-color: #388c8c!important;
    border-radius: 1vh;
  }
  /* Define styles for smaller screens */
@media screen and (max-width: 960px) {
  .uk-navbar-left,
  .uk-navbar-right {
    display: none; /* Hide regular navigation links */
  }

  .sidebar-container {
    display: none !important;
  }
  .outlet-container {
    position: absolute;
    
  }

  .menu-icon {
    display: block; /* Show the menu icon */
  }
}

@media screen and (min-width: 961px){
  .hide-icon{
    display: none;
  }
}

body {
  font-family: Roboto, sans-serif;
}

.uk-checkbox-grey {
  background-color: grey !important;
}
.black-border{
  border: 1px solid grey !important;
}

.chart-container {
  /* Remove default width */
  width: 100% !important;
}

/* Define custom badge styles */
.uk-badge-custom {
  height:6px;
  width: 10px;
  border-radius: 5px;
  background-color: #59bec0;
  margin: 6px 0px;
}

.uk-badge-custom2 {
  width: 2vh;
  height: 1vh;
  border-radius: 20px;
  background-color: #adddde;
}
`, "",{"version":3,"sources":["webpack://./src/pages/links.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,kBAAkB;EACpB;EACA,sCAAsC;AACxC;EACE;;IAEE,aAAa,EAAE,kCAAkC;EACnD;;EAEA;IACE,wBAAwB;EAC1B;EACA;IACE,kBAAkB;;EAEpB;;EAEA;IACE,cAAc,EAAE,uBAAuB;EACzC;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAGA;EACE,+BAA+B;AACjC;;AAEA;EACE,iCAAiC;AACnC;AACA;EACE,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA,+BAA+B;AAC/B;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[".active {\n    background-color: #388c8c!important;\n    border-radius: 1vh;\n  }\n  /* Define styles for smaller screens */\n@media screen and (max-width: 960px) {\n  .uk-navbar-left,\n  .uk-navbar-right {\n    display: none; /* Hide regular navigation links */\n  }\n\n  .sidebar-container {\n    display: none !important;\n  }\n  .outlet-container {\n    position: absolute;\n    \n  }\n\n  .menu-icon {\n    display: block; /* Show the menu icon */\n  }\n}\n\n@media screen and (min-width: 961px){\n  .hide-icon{\n    display: none;\n  }\n}\n@import url(https://fonts.googleapis.com/css?family=Roboto);\n\nbody {\n  font-family: Roboto, sans-serif;\n}\n\n.uk-checkbox-grey {\n  background-color: grey !important;\n}\n.black-border{\n  border: 1px solid grey !important;\n}\n\n.chart-container {\n  /* Remove default width */\n  width: 100% !important;\n}\n\n/* Define custom badge styles */\n.uk-badge-custom {\n  height:6px;\n  width: 10px;\n  border-radius: 5px;\n  background-color: #59bec0;\n  margin: 6px 0px;\n}\n\n.uk-badge-custom2 {\n  width: 2vh;\n  height: 1vh;\n  border-radius: 20px;\n  background-color: #adddde;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import Pichart from "./Pichart";
import '../../links.css'

function Contribution() {
  return (
    <>
      {/* <div style={{ background: "#fcfcfc", borderRadius: "6px" }}>
        <div
          style={{
            background: "#f4f4f5",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            padding: "1.5vh",
          }}
        >
          <b style={{ color: "black", margin: "1vw" }}>Contributions</b>
        </div>
        <div uk-grid="true">
          <div>
            <div className="uk-card uk-padding-small uk-margin-small-top">
              <div className="uk-margin">
                <h4 className=" uk-margin-remove">
                  <b>$87,495</b>
                </h4>
                <p
                  className=" uk-margin-remove"
                  style={{
                    fontSize: "1.5vh",
                    width: "15vw",
                    color: "#5FBFBF",
                  }}
                >
                  Total Amount Raised
                </p>
              </div>
              <div className="uk-margin">
                <h4 className=" uk-margin-remove">
                  <b>7,127</b>
                </h4>
                <p
                  className=" uk-margin-remove"
                  style={{
                    fontSize: "1.5vh",
                    width: "15vw",
                    color: "#5FBFBF",
                  }}
                >
                  Number of Contributions
                </p>
              </div>
              <div className="uk-margin">
                <h4 className=" uk-margin-remove">
                  <b>$50</b>
                </h4>
                <p
                  className=" uk-margin-remove"
                  style={{
                    fontSize: "1.5vh",
                    width: "15vw",
                    color: "#5FBFBF",
                  }}
                >
                  Most Common Contribution
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="uk-card">
              <div className=" uk-card-body uk uk-padding-remove">
                <div style={{ marginLeft: "1vh", marginTop: "-1.5vh" }}>
                  <Pichart />
                </div>
              </div>
              <div
                style={{
                  width: "80vw",
                  fontSize: "1.5vh",
                  display: "flex",
                  marginLeft: "3vw",
                }}
              >
                <div
                  style={{
                    height: "1vh",
                    width: "2vh",
                    marginTop: "0.6vh",
                    borderRadius: "20px",
                    marginRight: "1vh",
                    background: "#59bec0",
                  }}
                ></div>
                <div>This Month</div>
                <div
                  style={{
                    height: "1vh",
                    width: "2vh",
                    borderRadius: "20px",
                    margin: " 0.6vh 1vh",
                    background: "#adddde",
                  }}
                ></div>
                <div>Last Month</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

<div style={{ background: "#fcfcfc", borderRadius: "6px" }}>
        <div
          style={{
            background: "#f4f4f5",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            padding: "1.5vh",
          }}
        >
          <b style={{ color: "black", margin: "1vw" }}>Contributions</b>
        </div>
        <div class=" uk-child-width-1-2@s uk-flex uk-margin" >
    
    <div>
        <div class="uk-margin-small-left uk-margin-top">
        <div className="uk-margin uk-width-expand">
              <h4 className=" uk-margin-remove">
                <b>$87,495</b>
              </h4>
              <p
                className=" uk-margin-remove"
                style={{
                  fontSize: "1.5vh",
                  color: "#5FBFBF",
                }}
              >
                Total Amount Raised
              </p>
            </div>
            <div className="uk-margin uk-width-expand">
              <h4 className=" uk-margin-remove">
                <b>7,127</b>
              </h4>
              <p
                className=" uk-margin-remove"
                style={{
                  fontSize: "1.5vh",
                  color: "#5FBFBF",
                }}
              >
                Number of Contributions
              </p>
            </div>
            <div className="uk-margin uk-width-expand">
              <h4 className=" uk-margin-remove">
                <b>$50</b>
              </h4>
              <p
                className=" uk-margin-remove"
                style={{
                  fontSize: "1.39vh",
                  color: "#5FBFBF",
                }}
              >
                Most Common Contribution
              </p>
            </div>
        </div>
    </div>
    <div className="uk-margin-right">
    <div >
                <Pichart />
              </div>
              <div className='uk-flex uk-margin-small-left' style={{paddingLeft:'14px'}} >
                  <div className="uk-flex">
                    <p className="uk-badge-custom"></p>
                    <div className="uk-margin-small-right" style={{fontSize:'1.8vh'}}>This Month</div>
                  </div>
                  <div className="uk-flex uk-padding-remove uk-width-expand">
                    <p className="uk-badge-custom"></p>
                    <div style={{fontSize:'1.8vh'}}>Last Month</div>
                  </div>
                  </div>
    </div>
</div>
      </div>
    </>
  );
}

export default Contribution;

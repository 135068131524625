// by me
// import React from 'react'
// import { Link, useLocation } from "react-router-dom";
// import "./links.css";

// function SideNavbar() {

//   const location = useLocation();
//   const { pathname } = location;
//   const splitLocation = pathname?.split("/")
  

//   return <>
//   <ul className='uk-list '>
//     <li className= {splitLocation[1] === "dashboard" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/dashboard"><span className='uk-margin-right' uk-icon="icon: home; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white' ,height:'2vh',width:'8vw', fontSize:'2.5vh'}}>Dashboard</span></Link></li>
//     <li className={splitLocation[1] === "contact" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/contact"><span className='uk-margin-right' uk-icon="icon: user; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white' ,height:'2vh',width:'8vw', fontSize:'2.5vh'}}>Contacts</span></Link></li>
//     <li className={splitLocation[1] === "map" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/map"><span className='uk-margin-right' uk-icon="icon:  location; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white' ,height:'2vh',width:'8vw', fontSize:'2.5vh'}}>Map</span></Link></li>
//     <li className={splitLocation[1] === "calendar" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/calendar"><span className='uk-margin-right' uk-icon="icon:  calendar; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white' ,height:'2vh',width:'8vw', fontSize:'2.5vh'}}>Calendar</span></Link></li>
//     <li className={splitLocation[1] === "tasks" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/tasks"><span className='uk-margin-right' uk-icon="icon: file-edit; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white' ,height:'2vh',width:'8vw', fontSize:'2.5vh'}}>Tasks</span></Link></li>
//     <li className={splitLocation[1] === "finance" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/finance"><span className='uk-margin-right' uk-icon="icon: credit-card; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white' ,height:'2vh',width:'8vw', fontSize:'2.5vh'}}>Finance</span></Link></li>
//     <li className={splitLocation[1] === "reports" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/reports"><span className='uk-margin-right' uk-icon="icon: file-text; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white' ,height:'2vh',width:'8vw', fontSize:'2.5vh'}}>Reports</span></Link></li>
//     <li className={splitLocation[1] === "setup" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/setup"><span className='uk-margin-right' uk-icon="icon:lifesaver; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white' ,height:'2vh',width:'15vw', fontSize:'2.5vh'}}>Setup & Strategy</span></Link></li>
//     <li className={splitLocation[1] === "settings" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/settings"><span className='uk-margin-right' uk-icon="icon: cog; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white' ,height:'2vh',width:'8vw', fontSize:'2.5vh'}}>Settings</span></Link></li>
//   </ul>
//   </>
// }

// export default SideNavbar

import React from 'react'
import { Link, useLocation } from "react-router-dom";
import "./links.css";

function SideNavbar() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname?.split("/");

  return (
    <>
      <ul className='uk-list'>
        <li className={splitLocation[1] === "dashboard" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/dashboard"><span className='uk-margin-right' uk-icon="icon: home; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white'}}>Dashboard</span></Link></li>
        <li className={splitLocation[1] === "contact" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/contact"><span className='uk-margin-right' uk-icon="icon: user; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white'}}>Contacts</span></Link></li>
        <li className={splitLocation[1] === "map" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/map"><span className='uk-margin-right' uk-icon="icon:  location; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white'}}>Map</span></Link></li>
        <li className={splitLocation[1] === "calendar" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/calendar"><span className='uk-margin-right' uk-icon="icon:  calendar; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white'}}>Calendar</span></Link></li>
        <li className={splitLocation[1] === "tasks" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/tasks"><span className='uk-margin-right' uk-icon="icon: file-edit; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white'}}>Tasks</span></Link></li>
        <li className={splitLocation[1] === "finance" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/finance"><span className='uk-margin-right' uk-icon="icon: credit-card; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white'}}>Finance</span></Link></li>
        <li className={splitLocation[1] === "reports" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/reports"><span className='uk-margin-right' uk-icon="icon: file-text; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white'}}>Reports</span></Link></li>
        <li className={splitLocation[1] === "setup" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/setup"><span className='uk-margin-right' uk-icon="icon:lifesaver; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white'}}>Setup & Strategy</span></Link></li>
        <li className={splitLocation[1] === "settings" ? "active uk-text-bold uk-padding-small"  : ' uk-text-bold uk-padding-small'}><Link className='uk-flex uk-link-reset' to="/settings"><span className='uk-margin-right' uk-icon="icon: cog; ratio:1.2;" style={{ color: "white" }}></span><span style={{color:'white'}}>Settings</span></Link></li>
      </ul>
    </>
  );
}

export default SideNavbar;

// import React, { useRef, useEffect } from 'react';
// import Chart from 'chart.js/auto';



// const Charts = ({ expensesData, incomeData }) => {
//     const chartContainer = useRef(null);
//   const chartInstance = useRef(null);

//   useEffect(() => {
//     if (chartInstance.current) {
//       chartInstance.current.destroy();
//     }

//     const ctx = chartContainer.current.getContext('2d');
//     chartInstance.current = new Chart(ctx, {
//       type: 'bar',
//       data: {
//         labels: ["Month 1", "Month 2", "Month 3", "Month 4", "Month 5", "Month 6"],
//         datasets: [
//           {
//             label: "Expenses",
//             backgroundColor: "rgba(255, 99, 132, 0.2)",
//             borderColor: "rgba(255, 99, 132, 1)",
//             borderWidth: 1,
//             data: expensesData
//           },
//           {
//             label: "Income",
//             backgroundColor: "rgba(54, 162, 235, 0.2)",
//             borderColor: "rgba(54, 162, 235, 1)",
//             borderWidth: 1,
//             data: incomeData
//           }
//         ]
//       },
//       options: {
//         scales: {
//           x: {
//             type: 'category', // Use category scale for x-axis
//             offset: true,
//           },
//           y: {
//             beginAtZero: true
//           }
//         }
//       }
//     });

//     return () => {
//       if (chartInstance.current) {
//         chartInstance.current.destroy();
//       }
//     };
//   }, [expensesData, incomeData]);

//   return <canvas ref={chartContainer} />;
// }
  
// const chartContainer = useRef(null);
//   const chartInstance = useRef(null);

//   useEffect(() => {
//     if (chartInstance.current) {
//       chartInstance.current.destroy();
//     }

//     const ctx = chartContainer.current.getContext('2d');
//     chartInstance.current = new Chart(ctx, {
//       type: 'bar',
//       data: {
//         labels: ["jan 2023", "Feb 2023", "March 2023", "Apr 2023", "Mar 2023", "Jun 2023"],
//         datasets: [
//           {
//             label: "Expenses",
//             backgroundColor: "#b29ce1",
//             borderRadius: 10,
//             data: expensesData
//           },
//           {
//             label: "Income",
//             backgroundColor: "#64449c",
//             borderRadius: 10,
//             data: incomeData
//           }
//         ]
//       },
//       options: {
//         scales: {
//           x: {
//             type: 'category', // Use category scale for x-axis
//             offset: true,
//           },
//           y: {
//             beginAtZero: true,
//             max: 20000, // Set max value for y-axis
//             ticks: {
//               stepSize: 5000,
//               callback: function(value, index, values) {
//                 return value === 0 ? '0' : (value / 1000) + 'k';
//               }
//             }
//           }
//         }
//       }
//     });

//     return () => {
//       if (chartInstance.current) {
//         chartInstance.current.destroy();
//       }
//     };
//   }, [expensesData, incomeData]);

//   return <canvas ref={chartContainer} />;
// }



// export default Charts;




import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';
import '../../links.css'; 

const Charts = ({ expensesData, incomeData }) => {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const options = {
      chart: {
        type: 'bar',
        toolbar: {
          show: false // Hide toolbar
        },
        animations: {
          enabled: false // Disable animations for better responsiveness
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          horizontal: false,
          columnWidth: '30%', // Adjust bar width
        },
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#b29ce1', '#64449c'],
      xaxis: {
        categories: ["Jan 2023", "Feb 2023", "March 2023", "Apr 2023", "Mar 2023", "Jun 2023"],
        labels: {
          style: {
            fontSize: '1.5vh' // Set font size of categories
          }
        }
      },
      yaxis: [
        {
          min: 0,
          max: 20000,
          tickAmount: 5,
          labels: {
            formatter: function (val) {
              return val === 0 ? '' : (val / 1000) + 'k';
            }
          }
        }
      ],
      legend: {
        position: 'top', // Move legend to the top
      },
      responsive: [{
        breakpoint: 768, // Define breakpoint for responsiveness
        options: {
          chart: {
            height: 300 // Adjust chart height for smaller screens
          }
        }
      }]
    };

    const series = [
      {
        name: 'Contacted',
        data: expensesData
      },
      {
        name: 'Reached',
        data: incomeData
      }
    ];

    chartInstance.current = new ApexCharts(chartContainer.current, {
      ...options,
      series: series
    });

    chartInstance.current.render();

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [expensesData, incomeData]);

  return <div ref={chartContainer} className="chart-container"/>;
}

export default Charts;

// import React, { useEffect, useRef } from 'react';
// import ApexCharts from 'apexcharts';

// const Pichart = () => {
//   const chartRef = useRef(null);

//   useEffect(() => {
//     // Ensure the container element exists
//     if (!chartRef.current) return;

//     const options = {
//       chart: {
//         type: 'radialBar',
//         height: '250', // Decrease the chart height
//         width: '210', // Decrease the chart width
//       },
//       series: [67, 84],
//       plotOptions: {
//         radialBar: {
//           dataLabels: {
//             total: {
//               show: true,
//               label: 'TOTAL',
//             },
//           },
//           hollow: {
//             size: '50%', // Decrease the size of the chart
//           },
//           track: {
//             strokeWidth: '100', // Increase the line size
//           },
//         },
//       },
//       labels: ['TEAM A', 'TEAM B'],
//     };

//     // Create the chart instance
//     const chartInstance = new ApexCharts(chartRef.current, options);

//     // Render the chart
//     chartInstance.render();

//     // Cleanup function to destroy the chart instance when component unmounts
//     return () => {
//       chartInstance.destroy();
//     };
//   }, []); // Empty dependency array ensures this effect runs only once after initial render

//   return <div ref={chartRef} />;
// };

// export default Pichart;



import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';

const Pichart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Ensure the container element exists
    if (!chartRef.current) return;

    const options = {
      chart: {
        type: 'radialBar',
        height: '200', // Decrease the chart height
        width: '190', // Decrease the chart width
      },
      series: [80,71 ],
      plotOptions: {
        radialBar: {
          dataLabels: {
            show: true,
          },
          hollow: {
            size: '50%', // Decrease the size of the chart
          },
          track: {
            strokeWidth: 100, // Increase the line size
            background: '#dce0e8', // Track background color
          },

        },
      },
      colors: ['#59bec0', '#adddde'], // Series colors
      labels: ['TEAM A', 'TEAM B']
    };

    // Create the chart instance
    const chartInstance = new ApexCharts(chartRef.current, options);

    // Render the chart
    chartInstance.render();

    // Cleanup function to destroy the chart instance when component unmounts
    return () => {
      chartInstance.destroy();
    };
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  return <div ref={chartRef} />;
};

export default Pichart;

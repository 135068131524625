import './App.css';
import Root from './routes';

function App() {

  return (
    <>
      <Root/>
    </>
  );
}

export default App;
// import React from 'react'

// function Finance() {
//   return (
//     <div>Finance</div>
//   )
// }

// export default Finance

// import React, { useEffect } from 'react';
// import ApexCharts from 'apexcharts';
// import "./links.css";

// function Finance() {
//   useEffect(() => {
//     const options = {
//       chart: {
//         height: 350,
//         type: "line",
//         stacked: false
//       },
//       dataLabels: {
//         enabled: false
//       },
//       series: [
//         {
//           name: 'Column A',
//           type: 'column',
//           data: [21.1, 23, 33.1, 34, 44.1, 44.9]
//         },
//         {
//           name: "Column B",
//           type: 'column',
//           data: [10, 19, 27, 26, 34, 35]
//         }
//       ],
//       stroke: {
//         width: [4, 4]
//       },
//       plotOptions: {
//         bar: {
//           columnWidth: "20%"
//         }
//       },
//       xaxis: {
//         categories:["Jan 2023", "Feb 2023", "Mar 2023", "Apr 2023", "May 2023", "Jun 2023"]
//       },
//       yaxis: [
//         {
//           seriesName: 'Column A',
//           axisTicks: {
//             show: true
//           },
//           axisBorder: {
//             show: true,
//           },
//           min: 0 // Ensure y-axis starts from zero
//         },
//         {
//           seriesName: 'Column B',
//           show: false
//         }
//       ],
//       tooltip: {
//         shared: false,
//         intersect: true,
//         x: {
//           show: false
//         }
//       },
//       legend: {
//         horizontalAlign: "left",
//         offsetX: 40
//       }
//     };

//     const chart = new ApexCharts(document.querySelector("#chart"), options);
//     chart.render();

//     // Clean up function to destroy the chart on component unmount
//     return () => chart.destroy();
//   }, []);

//   return (
//     <div id="chart"></div>
//   );
// }

// export default Finance;


import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';
import "./links.css"; // Import your CSS file here

function Finance() {
  useEffect(() => {
    const options = {
      chart: {
        height: 350,
        type: "line",
        stacked: false
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#99C2A2', '#C5EDAC', '#66C7F4'],
      series: [
        {
          name: 'Column A',
          type: 'column',
          data: [21.1, 23, 33.1, 34, 44.1, 44.9, 56.5, 58.5]
        },
        {
          name: "Column B",
          type: 'column',
          data: [10, 19, 27, 26, 34, 35, 40, 38]
        },
        {
          name: "Line C",
          type: 'line',
          data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
        },
      ],
      stroke: {
        width: [4, 4, 4]
      },
      plotOptions: {
        bar: {
          columnWidth: "20%"
        }
      },
      xaxis: {
        categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
      },
      yaxis: [
        {
          seriesName: 'Column A',
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Columns"
          }
        },
        {
          seriesName: 'Column A',
          show: false
        }, {
          opposite: true,
          seriesName: 'Line C',
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Line"
          }
        }
      ],
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          show: false
        }
      },
      legend: {
        horizontalAlign: "left",
        offsetX: 40
      }
    };

    const chart = new ApexCharts(document.querySelector("#chart"), options);
    chart.render();

    // Cleanup
    return () => {
      chart.destroy();
    };
  }, []); // Empty dependency array to run the effect only once

  return <div id="chart" />;
}

export default Finance;
